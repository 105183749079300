<template>
  <div>
    <!-- lead-search -->
    <!-- <div class="lead-search-area">
            <div class="row">
                <div class="col-sm-6 col-md-6 col-lg-3"></div>
                <div class="col-sm-6 col-md-6 col-lg-4">
                    <div class="lead-search">
                        <form action="">
                            <div class="form-group">
                                <label>Start</label>
                                <select class="form-control">
                                    <option>30 Days Ago</option>
                                    <option>Ralative Date</option>
                                    <option>Ralative Date</option>
                                </select>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3">
                    <div class="lead-search">
                        <form action="">
                            <div class="form-group">
                                <label>End</label>
                                <select class="form-control">
                                    <option>Today</option>
                                    <option>Ralative Date</option>
                                    <option>Ralative Date</option>
                                </select>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-2">
                    <div class="lead-search">
                        <a class="refresh" href="#"><img class="img-fluid" src="assets/img/refresh.svg" alt="" /> Refresh</a>
                    </div>
                </div>
            </div>
        </div> -->
    <!-- lead-search -->

    <!-- leads-table-area  -->
    <div class="leads-table-area addcustomer-table">
      <table class="display" id="inv_table" style="opacity: 0;">
        <thead>
          <tr>
            <th>#</th>
            <th>Image</th>
            <th>Year</th>
            <th>Make</th>
            <th>Model</th>
            <th>Engine</th>
            <th>Mileage</th>
            <th>Fuel</th>
            <th>Stock#</th>
            <th>Condition</th>
            <th>MSRP</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(inv, i) in allInventory"
            :key="inv.id"
            @click="openModal(inv)"
            class="rowinven"
          >
            <td>{{ i + 1 }}</td>
            <td>
              <img
                v-if="inv.imagelist"
                :src="inv.imagelist | splitVehicleImage"
                class="img-fluid rounded"
                style="max-width: 80px"
              />
              <img
                v-else
                style="max-width: 80px"
                src="../../assets/img/carplaceholder.png"
                class="img-fluid rounded"
              />
            </td>
            <td>{{ inv.year }}</td>
            <td>{{ inv.make }}</td>
            <td>{{ inv.model }}</td>
            <td>{{ inv.engine }}</td>
            <td v-if="inv.mileage">{{ inv.mileage | number("0,0") }}</td>
            <td v-else>N/A</td>
            <td>{{ inv.fuel }}</td>
            <td>{{ inv.stock_no }}</td>
            <td>{{ inv.new_used }}</td>
            <template v-if="inv.msrp">
              <td v-if="inv.msrp !== ''">${{ inv.msrp | number("0,0") }}</td>
              <td v-else>{{ inv.msrp }}</td>
            </template>
            <template v-else>
              <td>{{ inv.msrp }}</td>
            </template>
            <template v-if="inv.price">
              <td v-if="inv.price !== ''">${{ inv.price | number("0,0") }}</td>
              <td v-else>{{ inv.price }}</td>
            </template>
            <template v-else>
              <td>{{ inv.price }}</td>
            </template>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- leads-table-area  -->

    <div
      class="modal fade"
      id="vehicleDetailModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="vehicleDetailModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <a class="delas-colse close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"
              ><img
                class="img-fluid"
                src="../../assets/img/23. Closex.svg"
                alt=""
            /></span>
          </a>
          <div class="modal-body Intersets-modal">
            <div class="row">
              <div class="col-lg-7">
                <div class="exzoom" id="exzoom">
                  <div class="exzoom_img_box">
                    <ul class="exzoom_img_ul">
                      <li>
                        <img
                          v-if="vehicle.img"
                          class="img-fluid car"
                          :src="vehicle.img | splitVehicleImage"
                          alt=""
                        />
                        <img
                          v-else
                          class="img-fluid"
                          src="../../assets/img/carplaceholder.png"
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-5">
                <div class="details-modal">
                  <table class="table">
                    <tbody>
                      <tr>
                        <td colspan="2">
                          <h5 style="color: #000">
                            {{ vehicle.year }} {{ vehicle.make }}
                            {{ vehicle.model }}
                          </h5>
                        </td>
                      </tr>
                      <!-- <tr>
                                                <td>Year</td>
                                                <td>{{vehicle.year}}</td>
                                            </tr>
                                            <tr>
                                                <td>Make</td>
                                                <td>{{vehicle.make}}</td>
                                            </tr> -->
                      <tr>
                        <td class="td">Stock#</td>
                        <td>{{ vehicle.stockno }}</td>
                      </tr>
                      <tr>
                        <td class="td">Mileage</td>
                        <td>{{ vehicle.mileage | number("0,0") }}</td>
                      </tr>
                      <tr>
                        <td class="td">Engine</td>
                        <td>{{ vehicle.engine }}</td>
                      </tr>
                      <tr>
                        <td class="td">Trim</td>
                        <td>{{ vehicle.trim }}</td>
                      </tr>
                      <tr>
                        <td class="td">Fuel</td>
                        <td>{{ vehicle.fuel }}</td>
                      </tr>

                      <tr>
                        <td class="td">Condition</td>
                        <td>{{ vehicle.condition }}</td>
                      </tr>
                      <tr>
                        <td class="td">Msrp</td>
                        <td>{{ vehicle.mrsp | currency }}</td>
                      </tr>
                      <tr>
                        <td class="td">Price</td>
                        <td>{{ vehicle.price | currency }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-12">
                <div class="notes-con" v-if="vehicle.description">
                  <h3>Description</h3>
                  <p v-html="vehicle.description"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import $ from "jquery";
import "datatables.net";
import "datatables.net-responsive";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
export default {
  name: "Inventory",
  data() {
    return {
      filters_: {},
      allInventory: [],
      loaded: false,
      vehicle: {},
    };
  },
  computed:{
    IsV2(){
      if(this.$storage.get('auth').user){
        if(this.$storage.get('auth').user.dealer_autolink_version){
          if(this.$storage.get('auth').user.dealer_autolink_version == 'v2'){
            return true;
          }
        }
      }
      return false;
    },
    IsV2TokenExist(){
      if(this.$storage.get('auth').user){
        if(this.$storage.get('auth').user.dealer_autolink_v2_auth_token){
          if(this.$storage.get('auth').user.dealer_autolink_v2_auth_token != ''){
            return true;
          }
        }
      }
      return false;
    },
    V2AuthHeader(){
      if(this.$storage.get('auth').user){
        if(this.$storage.get('auth').user.dealer_autolink_v2_auth_token){
          if(this.$storage.get('auth').user.dealer_autolink_v2_auth_token != ''){
            return {
              'Authorization' : 'Bearer ' + this.$storage.get('auth').user.dealer_autolink_v2_auth_token
            };
          }
        }
      }
      return {};
    }
  },
  created() {
    this.getInventory();
  },
  methods: {
    openModal(inv) {
      this.vehicle = {};
      $("#vehicleDetailModal").modal("show");
      this.vehicle.year = inv.year;
      this.vehicle.model = inv.model;
      this.vehicle.make = inv.make;
      this.vehicle.stockno = inv.stock_no;
      this.vehicle.engine = inv.engine;
      this.vehicle.condition = inv.new_used;
      this.vehicle.mrsp = inv.msrp;
      this.vehicle.price = inv.price;
      this.vehicle.description = inv.description;
      this.vehicle.img = inv.imagelist;
      this.vehicle.mileage = inv.mileage;
      this.vehicle.trim = inv.trim;
      this.vehicle.fuel = inv.fuel;
    },
    getInventory() {
      if(this.IsV2 && this.IsV2TokenExist){
        axios.post("https://api.dealersgear.com/api/crmInventory?limit=1000&offset=0",{},{
          headers : this.V2AuthHeader
        })
          .then((res) => {
            if (res.data.status) {
              this.allInventory = res.data.inventory;
              setTimeout(() => {
                $("#inv_table").addClass("nowrap").dataTable({
                  responsive: true,
                  fixedHeader: true,
                  stateSave: true,
                  processing: true,
                  autoWidth: true,
                });
                this.loaded = true;
              }, 1000);
            } else {
              this.allInventory = [];
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }else{
        axios
          .get(
            "https://autolinkme.com/webservices/crm/inventory?id=" +
              this.$storage.get("auth").user.dealer_autolink_id +
              "&limit=1000&offset=0&status=active"
          )
          .then((res) => {
            if (res.data.status) {
              this.allInventory = res.data.data;
              setTimeout(() => {
                $("#inv_table").addClass("nowrap").dataTable({
                  responsive: true,
                  fixedHeader: true,
                  processing: true,
                  autoWidth: true,
                  order: [[1, "desc"]],
                  columnDefs: [
                    {
                      targets: [3, 4, 6],
                      orderable: false,
                    },
                  ],
                });
                $("#inv_table").css('opacity',1);
                this.loaded = true;
              }, 1000);
            } else {
              this.allInventory = [];
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>

<style scoped>
@import "../../assets/css/dataTables.css";
#inv_table {
  min-width: 100%;
  opacity: 1;
}
table td {
  word-break: break-all;
}
tr:hover {
  cursor: pointer;
  background-color: #f5f5f5 !important;
}
.car {
  width: 359px;
}
.td {
  width: 80px;
}
</style>